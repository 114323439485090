import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledMessageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  rowGap: '8px',
  '.copy-recipients-wrapper': {
    display: 'flex',
    marginLeft: 'auto',
  },
  '.copy-recipient-span': {
    padding: '0 8px',
    cursor: 'pointer',
  },
  '&& .MuiInputBase-root': {
    fontSize: '.875rem',
    fontFamily: theme.fonts.robotoRegular,
    color: theme.palette.text.txt_default,
  },
}));

export const StyledItemWrapper = styled(Box)(() => ({
  display: 'grid',
  width: '100%',
  padding: '0px',
  gridTemplateColumns: '61px auto 50px',
  rowGap: '8px',
}));

export const StyledItemText = styled('span')(() => ({
  lineHeight: '28px',
}));
