import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { Box } from '@mui/system';
import  BasicAvatar  from 'common/components/avatars/basicAvatar';

export const RECIPIENTS_GAPS = {
  WRAPPER_PADDING_LEFT: 12,
  WRAPPER_COLUMN_GAP: 12,
  SHOW_MORE_WIDTH: 90,
};

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  padding: '8px 8px 0',
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  color: theme.palette.text.secondary_txt,
  lineHeight: '20px',
}));

export const GroupItems = styled('ul')(({ theme }) => ({
  padding: 0,
  paddingRight: '4px',
  maxHeight: '138px',
  overflow: 'auto',
  overflowX: 'hidden',
  ...theme.customScrollbar,
}));

export const StyledRecipientInput = styled(Box, {
  shouldForwardProp: (prop) => !['popperOffset', 'hasTruncatedChips'].includes(prop),
})(({ popperOffset, isFocused, hasTruncatedChips }) => ({
  '& .MuiAutocomplete-root': {
    minHeight: '28px',
    width: '100%',

    '& .MuiInputBase-root ': {
      maxHeight: '108px',
      overflow: 'auto',
      padding: `0 ${RECIPIENTS_GAPS.WRAPPER_PADDING_LEFT}px 0 0`,
      gap: `8px ${RECIPIENTS_GAPS.WRAPPER_COLUMN_GAP}px`,
      '& .MuiAutocomplete-tag': {
        margin: '2px 0px',
      },
      '& .MuiAutocomplete-input': {
        padding: '0px',
      },
      '& .MuiInputBase-input ': {
        height: '28px',
        display: isFocused ? 'inline-block' : 'none',
        minWidth: '30px',
      },
      '& > :nth-last-child(2)': {
        // Push show-more/show-less chip to the right
        marginLeft: hasTruncatedChips && 'auto',
      },
    },
  },
  '.MuiAutocomplete-popper ': {
    overflow: 'hidden',
    boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
    minWidth: '224px',
    maxWidth: '224px !important',
    transform: `translate(${popperOffset.left}px, ${popperOffset.top}px) !important`,
    borderRadius: '6px',
    width: '100%',
    '& .MuiAutocomplete-listbox': {
      width: '100%',
      padding: '8px 4px 8px 8px',
    },
  },
}));

export const StyledChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'errorMode' })(
  ({ theme, errorMode }) => ({
    backgroundColor: !errorMode ? theme.palette.grey.bt_alt_grey_light : theme.palette.labels.bt_red_light,
    padding: '2px 8px 2px 0',
    borderRadius: '30px',
    gap: '4px',
    height: '24px',
    display: 'flex',
    cursor: 'pointer',
    minWidth: 'fit-content',
    '&:hover': {
      ...(errorMode && { backgroundColor: theme.palette.labels.bt_red_mid }),
    },
    '& .MuiChip-label': {
      fontSize: '.875rem',
      lineHeight: '1.25rem',
      paddingLeft: '6px',
      paddingRight: '0px',
      height: '20px',
      maxWidth: '239px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: !errorMode ? theme.palette.text.txt_default : theme.palette.labels.bt_red_reg,
    },
    '& .MuiChip-deleteIcon': {
      marginLeft: '8px',
    },
  }),
);

export const ShowMoreWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.bt_alt_grey_light,
  color: theme.palette.text.title_txt,
  padding: '2px 8px',
  cursor: 'pointer',
  borderRadius: '30px',
  lineHeight: '20px',
  width: `${RECIPIENTS_GAPS.SHOW_MORE_WIDTH}px`,
  textAlign: 'center',
}));

export const StyledBasicAvatar = styled(BasicAvatar)(({ theme, type }) => ({
  '&.MuiAvatar-root.MuiChip-avatar': {
    ...(type === 'group'
      ? {
          backgroundColor: theme?.palette?.blueLabels?.bt_status_reg,
          '& svg': {
            width: '12px',
            height: '12px',
          },
        }
      : {
          backgroundColor: theme?.palette?.blueLabels?.bt_blue_chip_light,
        }),
    height: theme?.avatars?.height.small,
    width: theme?.avatars?.height?.small,
    fontSize: theme?.avatars?.font_size?.medium,
    lineHeight: theme?.avatars?.line_height?.big,
    color: 'white',
    letterSpacing: '1px',
    marginLeft: '2px',
  },
}));

export const StyledUserOptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '42px',
  padding: '2px 8px',
  width: '100%',
  marginTop: '4px',
  '.MuiAvatar-root': {
    marginRight: '5px',
  },
  ':hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    cursor: 'pointer',
  },
}));

export const StyledUserOption = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const StyledOptionText = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  width: '160px',
  fontFamily: theme.fonts.robotoRegular,
  color: theme.palette.text.title_txt,
  '&.name': {
    fontSize: theme.fontSize.main,
    lineHeight: '20px',
  },
  '&.email-address': {
    lineHeight: '16px',
    fontSize: theme.fontSize.small,
    color: theme.palette.text.secondary_txt,
  },
  '& .matched-text': {
    color: `${theme.palette.text.blue_user_name} !important`,
  },
}));
