import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import smallYellowStarsIcon from 'images/icons/generativeAi/yellow_stars_16x16.gif';
import { TicketSummaryTooltip } from 'common/components/summaryPopup/Tooltip';
import { fieldValueExists } from 'common/utils/fieldUtils';
import { Box } from '@mui/system';
import { PlaceholderValues } from 'common/utils/constants';
import { isTicketPage as isTicketPageLocation } from 'common/utils/utils';

import { StyledCommonTextField } from './CommonTextField';
import { CONSTANTS } from '../constants';
import { StyledTooltip } from '../Tooltip/Tooltip';
import { SR_PANEL_CONSTANTS } from '../../../../features/srPanel/consts';

export const InlineField = (props) => {
  const {
    title,
    value,
    handleSave,
    setIsError,
    type,
    subType,
    unread,
    disabled,
    readOnly,
    isLocked,
    multiline,
    isMultilineRTF,
    isError,
    onChange,
    limit,
    fullwidth,
    source,
    inputRef,
    isOverflow,
    ticket,
    setIsOverflow,
    placeholder,
    required,
    isNewField,
    isTemplatePage,
    onClick,
    onFocus,
    fieldId,
    fieldTypeId,
    showSummaryTooltip = false,
    disableTooltip = false,
    text,
    texts,
    tooltipClassName,
    isNoEditPermission,
    showLimit = false,
    isAiEnabled,
    assistiveAI,
    ...rest
  } = props;

  const isTicketPage = useMemo(() => isTicketPageLocation(), []);

  const [rows, setRows] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const maxLength = 5000;
  const characterCount = useRef(value?.length || 0);

  const isLongText = useMemo(() => fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT, [fieldTypeId]);

  useEffect(() => {
    if (multiline) {
      setRows(3);
    }
  }, [multiline]);

  const handleKeyPress = (event) => {
    if (limit) {
      if (value?.length >= limit) {
        event.preventDefault();
      }
    } else if (value?.length >= CONSTANTS.INPUT_CHARACTERS_LIMIT) {
      event.preventDefault();
    }

    if (!multiline) {
      if (event.key === 'Enter' || (event.shiftKey && event.key === 'Enter')) {
        event.target.blur();
      }
    }
  };

  const onChangeValue = (event) => {
    if (fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT || showLimit) {
      characterCount.current = event.target.value.length;
    }
    onChange(event);
  };

  const handleFocus = () => {
    if (!disabled && onFocus) {
      onFocus(fieldId);
    }
    if (multiline) {
      setRows(3);
    }

    setIsFocused(true);
    setIsError(false);
    setIsOverflow(false);
  };

  const calculateMaxCharacters = (inputWidth, referenceWidth = 220, maxCharactersAtReference = 35) =>
    Math.floor((inputWidth / referenceWidth) * maxCharactersAtReference);

  const getIsOverflowValue = useCallback(
    () =>
      inputRef?.current?.scrollWidth > inputRef?.current?.offsetWidth ||
      multiline ||
      isMultilineRTF ||
      value?.length > calculateMaxCharacters(inputRef?.current?.offsetWidth),
    [inputRef, isMultilineRTF, multiline, value?.length],
  );

  const prevValue = usePreviousValue(text);

  const handleBlur = () => {
    if (!multiline) {
      setRows(1);
    }
    setIsFocused(false);

    if (handleSave && value !== prevValue) {
      handleSave(value);
    }
    if (!fieldValueExists({ value }) && required) {
      setIsError(true);
    }
    if (multiline) {
      inputRef.current.scrollTo(0, 0);
    }

    setIsOverflow(getIsOverflowValue());
  };

  const onMouseOver = () => {
    setIsOverflow(getIsOverflowValue());
  };
  const placeholderValue = useMemo(() => {
    if (isNoEditPermission && !value) {
      return PlaceholderValues.NoEditingPermissions;
    }

    if (required && !value) {
      return '';
    }
    if (placeholder) {
      return placeholder;
    }
    return PlaceholderValues.TypeHere;
  }, [required, value, placeholder, isNoEditPermission]);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const getEndAdornment = () => {
    if (isError) {
      return null;
    }
    if (showSummaryTooltip && isHovered && !isFocused) {
      return <img src={smallYellowStarsIcon} alt="" />;
    }
    return null;
  };

  const styledCommonTextField = (
    <StyledCommonTextField
      className={`inline-field ${isError ? 'with-error' : ''}`}
      variant="standard"
      disabled={disabled}
      isLocked={isLocked}
      error={isError}
      unread={unread?.toString()}
      ticket={ticket}
      placeholder={placeholderValue}
      value={value}
      onChange={onChangeValue}
      onKeyPress={handleKeyPress}
      onClick={onClick}
      isTicketPage={isTicketPage}
      onFocus={handleFocus}
      onBlur={handleBlur}
      multiline={multiline}
      source={source}
      fullwidth={fullwidth}
      rows={rows}
      inputRef={inputRef}
      isNewField={isNewField}
      isTemplatePage={isTemplatePage}
      isEmpty={!value}
      InputProps={{
        disableUnderline: true,
        disabled,
        readOnly,
        endAdornment: getEndAdornment(),
        'data-testid': 'inline-field',
      }}
      onMouseOver={onMouseOver}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isTextAreaFocused={isFocused && isLongText}
      helperText={
        (isFocused && isLongText) ||
        (showLimit && (
          <Box
            component="span"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingInlineEnd: '5px',
            }}
          >
            <span>{`${characterCount.current}/${maxLength}`}</span>
          </Box>
        ))
      }
      type={type}
      {...rest}
    />
  );

  if (multiline) {
    return styledCommonTextField;
  }

  if (isError) {
    return (
      <StyledTooltip arrow placement="top" tooltipClassName={tooltipClassName} title={texts?.mandatoryFieldError ?? ''}>
        {styledCommonTextField}
      </StyledTooltip>
    );
  }

  const commonTextField = disableTooltip ? (
    styledCommonTextField
  ) : (
    <StyledTooltip
      arrow
      placement="top"
      tooltipClassName={tooltipClassName}
      title={!isFocused && isOverflow ? title ?? value : ''}
    >
      {styledCommonTextField}
    </StyledTooltip>
  );

  return showSummaryTooltip ? (
    <TicketSummaryTooltip isWrappedInputFocused={isFocused} isAiEnabled={isAiEnabled} assistiveAI={assistiveAI}>
      {styledCommonTextField}
    </TicketSummaryTooltip>
  ) : (
    commonTextField
  );
};
