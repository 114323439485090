import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/base';

import MessagePrompt from 'common/components/messagePrompt';
import FilesPreview from 'common/components/thumbnail/FilesPreview';

import { useTexts } from './hooks/useTexts';
import { DEFAULT_CONFIG } from '../constants';
import { StyledUploadFromSelector, StyledUploadFromSelectorItem, StyledUploadFromSelectorWrapper } from '../styles';

const AttachmentUploader = (props) => {
  const {
    editorRef,
    imagePreviewConfig,
    errorMessage,
    onChangeImagePreviewsConfig,
    onChangeErrorMessage,
    onChange,
    onImageUpload,
    onFileAttach,
    uploadFromMenuId,
    setUploadFromMenuId,
    isUploadFromMenuOpened,
    setIsUploadFromMenuOpened,
    setIsUploadFormAttachmentsMenuOpened,
  } = props;

  const { uploadFromComputerText } = useTexts();

  const handleClosePreview = () => {
    editorRef.current.events.focus();
    onChangeImagePreviewsConfig({});
  };

  const handleImageRemove = ({ img }) => {
    img.remove();
    onChange(editorRef.current.html.get());
    handleClosePreview();
  };

  const handleClosePrompt = () => {
    onChangeErrorMessage('');
  };

  const awayMenuClickAwayListener = useCallback(() => {
    if (document.querySelector('.fr-tooltip')) document.querySelector('.fr-tooltip').style.display = 'block';
    setIsUploadFromMenuOpened(false);
    setUploadFromMenuId(null);
  }, [setIsUploadFromMenuOpened, setUploadFromMenuId]);

  const handleComputerUploadFromSelection = useCallback(() => {
    awayMenuClickAwayListener();
    const wrapper = document.querySelector(`#richtext-wrap-${uploadFromMenuId}`);
    const uploadButton = wrapper.querySelector('#file-uploader');
    uploadButton?.click();
  }, [awayMenuClickAwayListener, uploadFromMenuId]);

  const handleCurrentlyUploadedFromSelection = useCallback(() => {
    awayMenuClickAwayListener();
    setIsUploadFormAttachmentsMenuOpened(true);
  }, [awayMenuClickAwayListener, setIsUploadFormAttachmentsMenuOpened]);

  useEffect(() => {
    const modal = document.getElementById(`uploadFromWrapper-${uploadFromMenuId}`);
    const insertIcon = document.getElementById(`insertFile-${uploadFromMenuId}`);

    if (insertIcon && modal) {
      insertIcon.style = { position: 'relative' };
      const newModalRef = insertIcon?.appendChild(modal);

      newModalRef.style.display = 'block';
      insertIcon.addEventListener('click', handleComputerUploadFromSelection);
    }

    return () => {
      if (insertIcon) {
        
        insertIcon.removeEventListener('click', handleComputerUploadFromSelection);
        insertIcon.classList.remove('fr-btn-active-popup');
      }
    };
  }, [
    handleComputerUploadFromSelection,
    handleCurrentlyUploadedFromSelection,
    isUploadFromMenuOpened,
    uploadFromMenuId,
  ]);

  return (
    <>
      {imagePreviewConfig?.open && (
        <FilesPreview
          filesData={imagePreviewConfig.filesData}
          selectedFileIndex={imagePreviewConfig.selectedFileIndex}
          onClose={handleClosePreview}
          onFileRemove={handleImageRemove}
        />
      )}

      <input
        type="file"
        className="uploader"
        id="image-uploader"
        onChange={onImageUpload}
        accept={DEFAULT_CONFIG.imageTypes}
      />

      <input id="file-uploader" className="uploader" type="file" onChange={onFileAttach} />

      <ClickAwayListener onClickAway={awayMenuClickAwayListener}>
        <StyledUploadFromSelectorWrapper
          id={`uploadFromWrapper-${uploadFromMenuId}`}
          isUploadFromMenuOpened={isUploadFromMenuOpened}
          sx={{ display: 'none' }}
        >
          {isUploadFromMenuOpened && (
            <StyledUploadFromSelector>
              <StyledUploadFromSelectorItem id="computerUpload">{uploadFromComputerText}</StyledUploadFromSelectorItem>
            </StyledUploadFromSelector>
          )}
        </StyledUploadFromSelectorWrapper>
      </ClickAwayListener>

      {errorMessage && (
        <MessagePrompt open onClose={handleClosePrompt} onOkClick={handleClosePrompt}>
          {errorMessage}
        </MessagePrompt>
      )}
    </>
  );
};

AttachmentUploader.propTypes = {
  imagePreviewConfig: PropTypes.object,
  errorMessage: PropTypes.string,
  onChangeImagePreviewsConfig: PropTypes.func,
  onChangeErrorMessage: PropTypes.func,
  onChange: PropTypes.func,
  onImageUpload: PropTypes.func,
  onFileAttach: PropTypes.func,
};

AttachmentUploader.defaultProps = {
  imagePreviewConfig: {},
  errorMessage: '',
  onChangeImagePreviewsConfig: () => {},
  onChangeErrorMessage: () => {},
  onChange: () => {},
  onImageUpload: () => {},
  onFileAttach: () => {},
};
export default AttachmentUploader;
