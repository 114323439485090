import { useRouter } from '@tanstack/react-router';
import { MenuList, MenuItem } from '@mui/material';
import Tooltip from 'common/components/tooltip';
import CustomScrollBar from 'common/components/customScroll';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { useInitialContent } from '../../hooks/messageHooks';
import { StyledMenu } from './style';

const customScrollProps = {
  style: { display: 'flex' },
  autoHeight: true,
  autoHeightMax: 230,
  renderThumbVerticalStyle: { width: '4px' },
  renderTrackVerticalStyle: { width: '0px' },
  renderViewStyle: { flex: '1' },
  dataset: { 'data-testid': 'rich-text-dropdown-scrollbar' },
};

/**
 * This component is used to render the dropdown for canned responses
 * It is named as RichTextDropdown as in theory, it should be usable for any dropdown in the rich text editor
 * However, it is currently only used for canned responses
 */

export default function RichTextDropdown({ editorRef, pluginKey, toggleDropdown }) {
  const router = useRouter();
  const anchor = editorRef?.current?.$tb?.find(`.fr-command[data-cmd="${pluginKey}"]`);
  const srId = router.latestLocation.search.id;
  const { data } = useInitialContent(editorActionTypes.MESSAGE, srId);

  const handleSelect = (e) => {
    const formattedText = e?.target?.innerHTML?.replace(/(?:\r\n|\r|\n)/g, '<br>');
    if (!editorRef.current.html?.get()) {
      editorRef.current.html?.set(formattedText);
    } else {
      editorRef.current.html?.insert(formattedText);
    }
    toggleDropdown();
  };

  return (
    <StyledMenu
      open={!!editorRef}
      onClose={toggleDropdown}
      anchorEl={anchor?.[0]}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id="canned-response-menu"
    >
      <CustomScrollBar {...customScrollProps}>
        <MenuList>
          {data?.cannedResponses?.map((item) => (
            <Tooltip
              key={item.id}
              text={item.description}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Bold', lineHeight: '16px' }}
              onClick={handleSelect}
            >
              <MenuItem>{item.description}</MenuItem>
            </Tooltip>
          ))}
        </MenuList>
      </CustomScrollBar>
    </StyledMenu>
  );
}
