import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getRephrasedText } from 'services/sysaiService';
import { TRACK_EVENTS } from 'constants/trackEvents';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { formatLineBreaks } from './utils';
import {
  FROALA_SELECTION_END_MARKER,
  FROALA_SELECTION_START_MARKER,
  TOOLBAR_BOTTOM_POSITION,
  DISTANCE_FROM_EDITOR_TOOLBAR,
} from './constants';

export const useRephraseToolbar = ({ fieldName, editorRef, updateCharCount, isTemplatePage, srId }) => {
  const [canRevert, setCanRevert] = useState(false);
  const lastClickedActionRef = useRef();
  const prevHtmlRef = useRef('');
  const editor = editorRef.current;
  const toolbarRef = useRef();
  const abortControllerRef = useRef(new AbortController());
  const { isLoading, mutate } = useMutation(getRephrasedText);
  const trackEvents = useAnalytics();
  const editorToolbarHeight = editor?.$tb?.height();
  let bottomPosition = TOOLBAR_BOTTOM_POSITION;
  if (editorToolbarHeight) {
    bottomPosition = editorToolbarHeight + DISTANCE_FROM_EDITOR_TOOLBAR;
  }
  const saveOriginalHtmlWithSelection = () => {
    if (!editor) return;

    const { selection: selectionAPI, markers } = editor;
    // Place markers to allow restoring selection after replacing the text
    const selectedRange = selectionAPI.ranges(0);
    markers.place(selectedRange, true, 0);
    markers.place(selectedRange, false, 0);
    prevHtmlRef.current = editor.html.get(true);
  };

  const handleClick = async (rephraseType) => {
    lastClickedActionRef.current = rephraseType;

    if (!editor) return;

    trackEvents(TRACK_EVENTS.SPACES_AI_REPHRASE, { srField: fieldName, rephraseOption: rephraseType });

    const selectionAPI = editor.selection;
    let selectedText = selectionAPI.text();
    if (selectedText === "") {
      editor.commands.selectAll();
      selectedText = selectionAPI.text();
    }
    if (selectedText.trim() === "") return;
    if (!canRevert) {
      saveOriginalHtmlWithSelection();
    }

    const { signal } = abortControllerRef.current;

    const rephraseRequest = { type: rephraseType, text: selectedText };

    if (!isTemplatePage) {
      // Include srId only if rephrase came from queue/ticket page
      rephraseRequest.srId = srId;
    }

    mutate(
      { rephraseRequest, signal },
      {
        onSuccess: (data) => {
          const { text: rephrasedText } = data;

          editor.commands.clearFormatting();
          // Insert the result surrounded with selection markers to select the text after inserting it,
          // this is the cleanest solution to do so without looking for the DOM node
          // in which the selection needs to start and calculating the correct offset
          editor.html.insert(
            `${FROALA_SELECTION_START_MARKER}${formatLineBreaks(rephrasedText)}${FROALA_SELECTION_END_MARKER}`,
          );
          updateCharCount();

          selectionAPI.restore();

          setCanRevert(true);
        },
      },
    );
  };

  const revert = () => {
    if (!editor) return;

    if (canRevert) {
      setCanRevert(false);
      editor.html.set(prevHtmlRef.current);
      updateCharCount();
      editor.selection.restore();
    }
  };

  const preventTextSelectionResetWhenButtonsDisabled = (e) => e.preventDefault();

  return {
    toolbarProps: {
      toolbarRef,
      bottomPosition,
      lastClickedAction: lastClickedActionRef.current,
      isLoading,
      canRevert,
      handleClick,
      revert,
      preventTextSelectionResetWhenButtonsDisabled,
    },
  };
};
