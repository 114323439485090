import { useEffect, useState, forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from 'store/fieldValidationsSlice';
import { selectToasterMessage } from 'store/globalSlice';
import { StyledTooltip } from '../Tooltip/Tooltip';
import useTexts from '../useTexts';
import { CONSTANTS } from '../constants';
import { TitleTextField, StyledTitleTextField } from './TitleTextField';
import calculateTextWidth from '../calculateTextWidth';

export const TitleField = forwardRef((props, ref) => {
  const {
    text,
    value,
    onChange,
    handleSave,
    setIsError,
    limit,
    isError,
    disabled,
    fieldName,
    placeholder,
    className,
    isNewField,
    onFocus,
    onBlur,
    dataset,
    variant,
    isTemplatePage,
    required,
    isFieldContentSize,
    handleDisabledClick = () => {},
    ...otherProps
  } = props;

  const { enterTextPlaceholder, enterTitlePlaceholder } = useTexts();
  const inputRef = useRef();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isBlur, setIsBlur] = useState(true);
  const [wasInFocus, setWasInFocus] = useState(false);
  const forceShowValidation = useSelector(
    isNewField ? selectForceFloatingTicketShowValidationError : selectForceShowValidationError,
  );
  const toasterMessage = useSelector(selectToasterMessage);

  useEffect(() => {
    if (inputRef?.current && isBlur) {
      setIsEllipsisActive(calculateTextWidth(value, 20) + 40 > CONSTANTS.MAX_WIDTH);
    }
  }, [value, isBlur, inputRef]);

  const handleKeyPress = (event) => {
    if (limit) {
      if (value?.length >= limit) {
        event.preventDefault();
      }
    } else if (value?.length >= CONSTANTS.LIMIT_TITLE_CHARACTERS) {
      event.preventDefault();
    }

    if (event.key === 'Enter' || (event.shiftKey && event.key === 'Enter')) {
      event.target.blur();
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('Text');
    const newValue = value + pastedText;

    if (limit && newValue.length > limit) {
      event.preventDefault();
      event.target.value = newValue.substring(0, limit);
      onChange(event);
    } else if (newValue.length > CONSTANTS.LIMIT_TITLE_CHARACTERS) {
      event.preventDefault();
      event.target.value = newValue.substring(0, CONSTANTS.LIMIT_TITLE_CHARACTERS);
      onChange(event);
    }
  };

  const handleFocus = () => {
    if (onFocus) onFocus();
    if (!isNewField) setIsError(false);
    setIsBlur(false);
    setIsEllipsisActive(false);
  };

  const handleBlur = ({ target: { value: inputValue } }) => {
    if (onBlur) onBlur();

    if (!wasInFocus) {
      setWasInFocus(true);
    }
    setIsBlur(true);

    if (handleSave && text !== inputValue) {
      handleSave(value);
    }
    if (required && !value.length && !isNewField) {
      setIsError(true);
    }

    if (inputRef?.current && calculateTextWidth(value, 20) + 40 > inputRef?.current?.offsetWidth) {
      setIsEllipsisActive(true);
    }
  };

  const fieldPlaceholder = () => {
    if (placeholder) return placeholder;
    return fieldName === 'title' ? enterTitlePlaceholder : enterTextPlaceholder;
  };

  const getTextFieldWidth = (value) => {
    let textWidth = calculateTextWidth(value || fieldPlaceholder(), 20) + 40;
    textWidth = textWidth || 0;
    if (toasterMessage?.message) {
      return textWidth < CONSTANTS.MAX_WIDTH_WITH_MESSAGE ? textWidth : CONSTANTS.MAX_WIDTH_WITH_MESSAGE;
    }
    return textWidth < CONSTANTS.MAX_WIDTH ? textWidth : CONSTANTS.MAX_WIDTH;
  };
  const FieldWidth = isFieldContentSize ? getTextFieldWidth(value) : '100%';

  return (
    <StyledTooltip
      title={isEllipsisActive && value}
      arrow
      disableHoverListener={!isEllipsisActive}
      disableFocusListener
    >
      <StyledTitleTextField
        error={isError}
        maxWidth={CONSTANTS.MAX_WIDTH}
        width={FieldWidth}
        disabled={disabled}
        className={className}
        {...(disabled ? { onClick: handleDisabledClick } : {})}
      >
        <TitleTextField
          {...otherProps}
          forceShowValidation={forceShowValidation}
          ref={ref}
          inputRef={inputRef}
          variant="standard"
          error={isError}
          wasInFocus={wasInFocus}
          placeholder={fieldPlaceholder()}
          value={value}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          isNewField={isNewField}
          sysaidVariant={variant}
          InputProps={{
            ...dataset,
            disabled,
            disableUnderline: true,
          }}
          data-testid="title-field"
        />
      </StyledTitleTextField>
    </StyledTooltip>
  );
});
