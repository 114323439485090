import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useAuditLog';
import { checkFileType, handlePasteFile } from '../utils';

const useAttachmentControls = ({
  onChangeActiveUploads,
  onChangeUploadImageProgress,
  onChangeErrorMessage,
  onChangeUploadFileProgress,
  onImageUpload,
  onFileAttach,
  editorRef,
}) => {
  const uploadCandidateFilesRef = useRef({});
  const { getTicketAuditLogs } = useAuditLog();

  const clearFileInput = (event) => {
    event.target.value = '';
  };

  const handleFileUpload = (event) => {
    event.stopPropagation?.();
    event.preventDefault?.();
    const file = event.target.files[0];
    return file;
  };

  const handleUploadProgress = (id, progressEvent) => {
    onChangeActiveUploads({
      type: 'updateUpload',
      payload: {
        id,
        progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      },
    });
  };

  const handleNewAttachment = async (event) => {
    const id = uuidv4();
    try {
      const file = handleFileUpload(event);
      if (file) {
        uploadCandidateFilesRef.current = {
          ...uploadCandidateFilesRef.current,
          [id]: file,
        };
        onChangeActiveUploads({ type: 'addUpload', payload: { fileName: file.name, id } });

        await onFileAttach({
          file,
          uploadId: id,
          onProgressChange: (progressEvent) => handleUploadProgress?.(id, progressEvent),
        });
        await onChangeActiveUploads({ type: 'removeUpload', payload: { id } });
        delete uploadCandidateFilesRef.current[id];

        await getTicketAuditLogs();
      }
    } catch (error) {
      onChangeActiveUploads({ type: 'updateUpload', payload: { id, error: error.response?.inline } });
    } finally {
      onChangeUploadFileProgress?.(false);
    }
    clearFileInput(event);
  };

  const handleImageUpload = async (event) => {
    try {
      const file = handleFileUpload(event);
      const attachmentId = await onImageUpload({ file });
      const id = uuidv4();
      uploadCandidateFilesRef.current = {
        ...uploadCandidateFilesRef.current,
        [id]: file,
      };
      if (attachmentId) {
        onChangeActiveUploads({ type: 'addUpload', payload: { fileName: file.name, id } });
        await onFileAttach({
          file,
          uploadId: id,
          onProgressChange: (progressEvent) => handleUploadProgress?.(id, progressEvent),
        });
        await onChangeActiveUploads({ type: 'removeUpload', payload: { id } });
        delete uploadCandidateFilesRef.current[id];

        await getTicketAuditLogs();

        await editorRef.current?.image?.insert(`/spaces/api/attachments/${attachmentId}/payload?inline=true`, true, {
          'data-attachmentId': attachmentId,
        });
      }
    } catch (error) {
      onChangeErrorMessage(error.response?.prompt);
    } finally {
      onChangeUploadImageProgress?.(false);
    }
    clearFileInput(event);
  };

  const handleAttachmentDrop = (
    acceptedFiles,
    imageUploader = handleImageUpload,
    attachmentUploader = handleNewAttachment,
  ) => {
    const isImage = checkFileType(acceptedFiles[0]);
    const event = { target: { files: acceptedFiles } };
    if (isImage) {
      imageUploader(event);
    } else {
      attachmentUploader(event);
    }
  };

  const handlePasteAttachment = async (
    img,
    imageUploader = handleImageUpload,
    attachmentUploader = handleNewAttachment,
  ) => {
    const syntheticEvent = handlePasteFile(img);
    if (syntheticEvent) {
      const isImage = checkFileType(syntheticEvent.target.files[0]);
      if (isImage) {
        await imageUploader(syntheticEvent);
      } else {
        await attachmentUploader(syntheticEvent);
      }
    }
  };

  const handleUploadRetry = async (id) => {
    const uploadRetryFile = uploadCandidateFilesRef.current?.[id];
    if (uploadRetryFile) {
      delete uploadCandidateFilesRef.current[id];
      const event = { target: { files: [uploadRetryFile] } };
      handleNewAttachment(event);
    }
  };

  return {
    handlePasteAttachment,
    handleAttachmentDrop,
    handleImageUpload,
    handleNewAttachment,
    handleUploadRetry,
    uploadCandidateFilesRef, // exposing ref for testing purposes
  };
};

export default useAttachmentControls;
