import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import './index.css';

import ExpandMessage from 'images/icons/expandMessage.png';
import { RTL } from 'common/utils/utils';
import { queryEndPoints } from 'constant';

export const getRichTextValueByColumnName = (richTextFields, columnName) => {
  let parsedRichTextFields;
  try {
    parsedRichTextFields = JSON.parse(richTextFields);
  } catch (err) {
    parsedRichTextFields = richTextFields;
  }
  return parsedRichTextFields?.find((field) => field.columnName === columnName) || ''; // TODO Alex K should check by fieldId, needs API update
};

const convertToMessageDate = (timestamp, timezone) =>
  moment(timestamp)
    .tz(timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format('llll');

export const checkFileType = (file) => file.type.includes('image/');

export const handlePasteFile = (img) => {
  const base64Data = img.src.split(',')[1];
  const type = img.src.match(/data:([^;]+);base64,/)[1];
  img.remove();
  const extension = type.split('/')[1];
  const filename = `${uuidv4()}.${extension}`;

  const binaryData = atob(base64Data);

  const buffer = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    buffer[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([buffer], { type });
  const syntheticEvent = {
    target: {
      files: [new File([blob], filename, { type })],
    },
  };
  return syntheticEvent;
};
export const forwardMessageBody = (sender, messageText, timestamp, timezone) =>
  `<div>
    <p></p>
    <div class="toggle-content-display">
      <img class="toggle-img" src=${ExpandMessage} alt="expand-button" />
    </div>
      <div class="forwarded-message-wrapper">
        <span class="fowarded-message-metadata">
          On ${convertToMessageDate(timestamp, timezone)} ${sender.displayName}
          ${sender.emailAddress && `<span class="sender-email">&lt;${sender.emailAddress}&gt;&nbsp;</span>`} wrote:
        </span>
        <div class="forwarded-message-content">
            <div class="vertical-line"></div>
            <div>${messageText}</div>
        </div>
      </div>
  </div>`;

export const updateSrcDomain = (src) => {
  if (!src) return src;
  const currentDomain = window.location.hostname;

  const url = new URL(src);
  if (url?.hostname !== currentDomain) {
    url.hostname = currentDomain;
  }
  return url?.toString();
};

export const removeSrcDomain = (src) => {
  try {
    const url = new URL(src);
    const path = `${url?.pathname}${url?.search}`;

    const startIndex = path.indexOf(queryEndPoints.ATTACHMENTS);
    return path.substring(startIndex);
  } catch (error) {
    console.error('Error removing src domain: ', error);
    return src;
  }
};

export const createAttachmentDataFromImage = (image) => ({
  attachmentId: '1',
  fileId: '2',
  subResourceType: '3',
  isScreenCapture: true,
  src: image.src,
  fileName: 'Inline image from note',
  removeParams: {},
  extension: 'png',
});

export const getSignatureEl = (currentSignature, signatureDirection) => {
  // Temporarily escape <img> tags by replacing their spaces
  const escapedSignature = currentSignature
    ?.replace(/<img[^>]*>/g, (match) => match.replace(/\s/g, '__IMG_SPACE__'))
    // Replace newlines with <br> and spaces with &nbsp; outside of <img> tags
    .replace(/\n/g, '<br>')
    .replace(/(?!<img[^>]*>)\s+/g, '&nbsp;')
    // Restore the <img> tag spaces
    .replace(/__IMG_SPACE__/g, ' ');

  const directionStyle = signatureDirection === RTL ? 'margin-left: auto;' : '';
  return `<div class='email-signature' style='${directionStyle}'>${escapedSignature}</div>`;
};

/**
 * Processes inline images in an HTML element by updating their src attributes
 * to remove domain information for attachments.
 *
 * @param {HTMLElement} contentElement - The HTML element containing images to process
 * @returns {string} The updated innerHTML of the element after processing
 */
export const processInlineImages = (contentElement) => {
  if (!contentElement) return '';

  const inlineImages = Array.from(contentElement.querySelectorAll('img'));

  for (const image of inlineImages) {
    if (image.src.includes(queryEndPoints.ATTACHMENTS)) {
      const updatedSrc = removeSrcDomain(image.src);
      image.src = updatedSrc;
    }
  }

  return contentElement.innerHTML;
};
