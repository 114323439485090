import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useSelector } from 'react-redux';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from 'store/fieldValidationsSlice';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import { isQueue } from 'common/utils/utils';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { convertFromClientFieldId, fieldValueExists } from 'common/utils/fieldUtils';
import { getFieldAttributes } from 'services/ticketService';
import { ClickAwayListener, Fade, Paper } from '@mui/material';
import { isLocalHost } from 'services/localhost';
import MultiSelectChipList from './MultiSelectChipList';
import {
  MultiSelectFieldWrapper,
  StyledDropDownWrapper,
  StyledMultiSelectField,
  StyledMultiSelectFieldInner,
} from './style';
import { StyledPopper } from '../../sysaidLibrary/Select/style';

export default function MultiSelectField({
  itemList,
  selectedItemList,
  handleSelectItems,
  onFocus,
  keyString,
  captionString,
  fieldPlaceholder,
  srType,
  fieldId,
  customColumn,
  required,
  isTemplatePage,
  isNewField,
  isEmptyField,
  readOnly = false,
  disabled = false,
  isWorkFlowMultiList = false,
  columns,
  skipValidation,
  isSelectAllHidden,
  listLabel,
  searchPlaceholder,
  handleSaveOptions = null,
  customBackendQueryConfig,
  chipCaptionKey,
  noChipFixedWidth,
  isFloatingTicketPanel,
  isReadOnly,
  valueCaption,
}) {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const queryClient = useQueryClient();
  const forceShowError = useSelector(
    isNewField ? selectForceFloatingTicketShowValidationError : selectForceShowValidationError,
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPlusClicked, setIsPlusClicked] = useState(false);
  const [updateJourney, setUpdateJourney] = useState(false);
  const valueCaptionListRef = useRef([]);
  const globalRef = useRef(null);
  const multiSelectRef = useRef(null);
  const dropdownRef = useRef(null);
  const width = multiSelectRef?.current?.clientWidth;
  const mappedItems = useMemo(() => {
    const mapList = new Map();
    if (Array.isArray(itemList)) {
      itemList.forEach((item) => mapList.set(String(item[keyString]), item));
    }
    return mapList;
  }, [itemList, keyString]);

  useEffect(() => {
    if (valueCaption?.length) {
      valueCaptionListRef.current = valueCaption;
    }
  }, [valueCaption]);

  const chipList = useMemo(() => {
    if (Array.isArray(selectedItemList)) {
      const newChipList = selectedItemList?.reduce((result, item) => {
        if (mappedItems.has(String(item))) {
          result.push(mappedItems.get(String(item)));
        }
        return result;
      }, []);

      if (valueCaptionListRef.current.length) {
        const currentChipValueList = newChipList.map(({ value }) => value);
        const valueCaptionItemsToAdd = valueCaptionListRef.current.filter(item => !currentChipValueList.includes(item.value));
        const result = [...newChipList, ...valueCaptionItemsToAdd];

        return result;
      }

      return newChipList;
    }

    return [];
  }, [mappedItems, selectedItemList]);

  const handleOpenDropdown = useCallback(() => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
      handleSaveOptions?.(selectedItemList);
    } else {
      if (!disabled && !readOnly) {
        setIsDropdownOpen(true);
        setIsPlusClicked(true);
        setUpdateJourney(true);
      }
      if (onFocus) {
        onFocus();
      }
    }
  }, [isDropdownOpen, handleSaveOptions, disabled, readOnly, onFocus, selectedItemList]);

  const handleDropdownUpdate = useCallback(() => {
    if (!isDropdownOpen && updateJourney) {
      handleSaveOptions?.(selectedItemList);
      setUpdateJourney(false);
    }
  }, [isDropdownOpen, updateJourney, handleSaveOptions, selectedItemList]);

  useEffect(() => {
    handleDropdownUpdate();
  }, [handleDropdownUpdate, isDropdownOpen, updateJourney, handleSaveOptions, selectedItemList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleSaveOptions?.(selectedItemList);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleSaveOptions, isDropdownOpen, selectedItemList]);

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleChange = useCallback(
    (selectedValues, optionsMap) => {
      handleSelectItems(selectedValues, optionsMap);
    },
    [handleSelectItems],
  );

  const filterAndHandleSelection = (id) => {
    setIsPlusClicked(true);
    setUpdateJourney(true);
    const filteredItems = selectedItemList.filter((item) => item.toString() !== id.toString());
    handleSelectItems(filteredItems);
    handleSaveOptions?.(selectedItemList);

    if (valueCaptionListRef.current.length) {
      valueCaptionListRef.current = valueCaptionListRef.current.filter((item) => item.id !== id);
    }
  };

  const handleDeleteChip = (id) => {
    if (disabled || readOnly) return;
    if (onFocus && !isLocalHost()) {
      const callResult = onFocus();
      if (callResult instanceof Promise) {
        callResult.then(() => {
          filterAndHandleSelection(id);
        });
      } else {
        filterAndHandleSelection(id);
      }
    } else {
      filterAndHandleSelection(id);
    }
  };

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => {
        if (customBackendQueryConfig) {
          return customBackendQueryConfig({ query, srId, queryClient });
        }
        return getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { query, srType, customColumn });
      },
    }),
    [fieldId, srType, customColumn, customBackendQueryConfig, srId, queryClient],
  );

  const checkIsError = () => {
    const isEmptyRequiredValue =
      isPlusClicked &&
      required &&
      !fieldValueExists({
        value: chipList,
        fieldId,
        fieldTypeId: SR_PANEL_CONSTANTS.MULTI_SELECT,
      });
    const newFieldError = isNewField && isEmptyRequiredValue && forceShowError;
    const existingFieldError = !isNewField && isEmptyRequiredValue;
    return newFieldError || existingFieldError;
  };
  const isError = checkIsError() && !skipValidation;

  const isQueuePage = useMemo(() => isQueue(), []);

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <MultiSelectFieldWrapper
        className="multi-select-field-wrapper"
        ref={globalRef}
        error={isError}
        isTemplatePage={isTemplatePage}
        isQueue={isQueuePage}
        isEmptyField={isEmptyField}
        isNewField={isNewField}
        isReadOnly={isReadOnly}
        isFloatingTicketPanel={isFloatingTicketPanel}
        disabled={disabled}
        readOnly={readOnly}
        skipValidation={skipValidation}
        isSrPage // TODO remove this when onClick on whole multi select field is fixed (SR page)
      >
        <StyledMultiSelectField className="multi-select-field" ref={multiSelectRef}>
          <StyledMultiSelectFieldInner>
            <MultiSelectChipList
              isError={isError}
              handleDelete={handleDeleteChip}
              handleOpen={handleOpenDropdown}
              keyString={keyString}
              captionString={captionString}
              itemList={chipList}
              placeholder={fieldPlaceholder}
              parentHeight={multiSelectRef.current?.offsetHeight}
              disabled={disabled}
              readOnly={readOnly}
              isWorkFlowMultiList={isWorkFlowMultiList}
              withTruncatedChips
              skipValidation={skipValidation}
              chipCaptionKey={chipCaptionKey}
              noChipFixedWidth={noChipFixedWidth}
            />
          </StyledMultiSelectFieldInner>

          <StyledPopper
            open={isDropdownOpen}
            anchorEl={multiSelectRef?.current}
            placement="bottom-start"
            width={width}
            onClick={(e) => e.stopPropagation()}
            data-testid="common-multi-select"
            ref={dropdownRef}
            transition
            hasColumns={columns?.length}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <StyledDropDownWrapper data-testid="multi-select-dropdown" className="multi-select-dropdown">
                    <DropdownSelect
                      autoInputFocus
                      options={itemList}
                      handleChange={handleChange}
                      stylingVariant={itemClassNames.reporter}
                      captionString={captionString}
                      keyString={keyString}
                      isMultiple
                      backendQueryConfig={backendQueryConfig}
                      selection={selectedItemList}
                      columns={columns}
                      isSelectAllHidden={isSelectAllHidden}
                      listLabel={listLabel}
                      searchPlaceholder={searchPlaceholder}
                    />
                  </StyledDropDownWrapper>
                </Paper>
              </Fade>
            )}
          </StyledPopper>
        </StyledMultiSelectField>
      </MultiSelectFieldWrapper>
    </ClickAwayListener>
  );
}
